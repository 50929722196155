import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { Grid, Stack, Typography } from '@mui/material'

import { PHONE_MASK, PHONE_PLACEHOLDER } from 'common/constants/common'
import MaskField from 'common/components/MaskField'
import { useTranslations } from 'common/services/translations'
import { Protection } from 'common/components/Stepper/components'
import { ChoicesStepperSelectField } from 'common/components/SelectField'

import StudyLink from './StudyLink'

export default function GuardianDetailsStep() {
  const { gettext } = useTranslations()

  return (
    <Stack gap={3}>
      <Typography variant="h5" fontWeight={600}>
        {gettext("Parent/Guardian's Details")}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {gettext(
          'The designated parent/guardian will receive secure links via email and SMS to provide information about your child during this study.'
        )}
      </Typography>
      <Stack direction="row">
        <StudyLink />
      </Stack>
      <Protection />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Field
            component={TextField}
            name="guardian_first_name"
            label={gettext('Parent / Guardian First Name')}
            placeholder={gettext('First Name')}
            sx={{ width: '100%' }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            component={TextField}
            name="guardian_last_name"
            label={gettext('Parent / Guardian Last Name')}
            placeholder={gettext('Last Name')}
            sx={{ width: '100%' }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Field
        component={ChoicesStepperSelectField}
        name="guardian_relationship"
        path="PatientPID.guardian_relationship"
        InputProps={{
          label: gettext('Relationship to Child'),
          placeholder: gettext('Choose relationship'),
          InputLabelProps: { shrink: true },
        }}
      />
      <Field
        component={MaskField}
        name="contact_number"
        type="tel"
        label={gettext('Mobile Phone')}
        placeholder={PHONE_PLACEHOLDER}
        mask={PHONE_MASK}
        sx={{ width: '100%' }}
        InputLabelProps={{ shrink: true }}
      />
      <Field
        component={TextField}
        name="email"
        type="email"
        label={gettext('Contact Email')}
        placeholder={gettext('Contact Email')}
        sx={{ width: '100%' }}
        InputLabelProps={{ shrink: true }}
      />
      <Field
        component={TextField}
        name="postal_code"
        label={gettext('Post Code')}
        placeholder={gettext('Post Code')}
        sx={{ width: '100%' }}
        InputLabelProps={{ shrink: true }}
      />
    </Stack>
  )
}
