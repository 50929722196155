import { Field, useFormikContext } from 'formik'

import { Choice, ChoicesPath } from 'types'
import useChoices from 'common/hooks/useChoices'
import { RegistrationValues } from 'pages/RegistrationForm/types'

import StepperSelectField, {
  StepperSelectFieldProps,
} from './StepperSelectField'

type ChoicesStepperSelectFieldProps = StepperSelectFieldProps<Choice> & {
  path: ChoicesPath
}

export default function ChoicesStepperSelectField({
  path,
  ...props
}: ChoicesStepperSelectFieldProps) {
  const options = useChoices(path)
  const { setFieldTouched } = useFormikContext<RegistrationValues>()

  return (
    <Field
      {...props}
      notched
      displayEmpty
      component={StepperSelectField}
      options={options}
      onClose={() => {
        setFieldTouched(props.field.name, true, true)
      }}
    />
  )
}
