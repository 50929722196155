import { useCallback, useMemo } from 'react'
import { useFormikContext } from 'formik'
import { Stack, Typography } from '@mui/material'

import { interpolate, useTranslations } from 'common/services/translations'

import { RegistrationValues } from '../types'
import StudyLink from './StudyLink'
import SignatureField from 'common/components/SignatureField'

const fieldName = 'signature'

export default function SignatureStep() {
  const { gettext } = useTranslations()
  const { setFieldValue, getFieldProps } =
    useFormikContext<RegistrationValues>()

  const onChange = useCallback(
    (value: string) => setFieldValue(fieldName, value),
    [setFieldValue]
  )

  const defaultValue = useMemo(
    () => getFieldProps(fieldName)?.value as string | undefined,
    [getFieldProps]
  )

  return (
    <Stack gap={3}>
      <Typography variant="h5" fontWeight={600}>
        {gettext('Study Consent')}
      </Typography>
      <Stack direction="row">
        <StudyLink />
      </Stack>
      <Typography variant="body1" color="text.secondary">
        {gettext('Please provide your signature to consent to join the study.')}
        <br />
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {gettext('By signing, you confirm that:')}
        <ol>
          <li>
            {gettext(
              'I have read and understood the full digital information for the study on the above link and have had the chance to ask questions of the study team by visiting the '
            )}
            <a href="/contact/" target="_blank" rel="noopener noreferrer">
              {gettext('contact page')}
            </a>
            .
          </li>
          <li>
            {gettext(
              "I agree that my child’s GP or healthcare providers can be contacted to get information on my child's progress."
            )}
          </li>
          <li>
            {gettext(
              "I understand that authorised people in this hospital and regulatory authorities may look at my child's medical notes for this study."
            )}
          </li>
          <li>
            {gettext(
              "I understand that the research team will collect information, including multimedia, from my child's medical records and enter it into the study database for analysis."
            )}
          </li>
          <li>
            {gettext(
              'I understand that Elixir AI will hold my contact details and my child’s information, including personal data, medical data, and multimedia uploaded for the study, on behalf of the study Sponsor.'
            )}
          </li>
          <li>
            {gettext(
              'I agree to provide my personal information and contact details and understand that representatives of the study will contact me for the study as described in the study information above.'
            )}
          </li>
          <li>
            {gettext('I would like for my child to participate in the study.')}
          </li>
        </ol>
      </Typography>
      <SignatureField onChange={onChange} defaultValue={defaultValue} />
      <Typography
        variant="caption"
        color="text.secondary"
        sx={{
          a: {
            color: 'inherit',
            textDecoration: 'underline',
          },
        }}
        dangerouslySetInnerHTML={{
          __html: interpolate(
            gettext('By joining the study, you accept our %s and %s.'),
            [
              `<a href="/terms-and-conditions/" target="_blank">${gettext('Terms and Conditions')}</a>`,
              `<a href="/privacy-policy/" target="_blank">${gettext('Privacy Policy')}</a>`,
            ]
          ),
        }}
      />
    </Stack>
  )
}
