import * as Yup from 'yup'

import { Choice } from 'types'
import { PHONE_REGEX, POSTAL_REGEX } from 'common/constants/common'

export default function getValidationSchema(gettext: (text: string) => string) {
  return [
    Yup.object().shape({
      hospital: Yup.string().required(gettext('Required field')),
      hospital_if_not_listed: Yup.string(),
      first_name: Yup.string().required(gettext('Required field')),
      last_name: Yup.string().required(gettext('Required field')),
      date_of_birth: Yup.date().required(gettext('Required field')),
      nhs: Yup.string()
        .matches(/^\d{3}\s?\d{3}\s?\d{4}$/, gettext('Invalid NHS number'))
        .required(gettext('Required field')),
      ethnicity: Yup.object().required(gettext('Required field')),
      ethnicity_description: Yup.string().when('ethnicity', {
        is: (ethnicity: Choice | null) => ethnicity?.value.includes('other'),
        then: (schema) => schema.required(gettext('Required field')),
        otherwise: (schema) => schema,
      }),
    }),
    Yup.object().shape({
      guardian_first_name: Yup.string().required(gettext('Required field')),
      guardian_last_name: Yup.string().required(gettext('Required field')),
      guardian_relationship: Yup.object().required(gettext('Required field')),
      contact_number: Yup.string()
        .required(gettext('Required field'))
        .matches(PHONE_REGEX, gettext('Invalid phone number')),
      email: Yup.string()
        .email(gettext('Email is wrong'))
        .required(gettext('Required field')),
      postal_code: Yup.string()
        .required(gettext('Required field'))
        .matches(POSTAL_REGEX, gettext('Invalid postal code')),
    }),
    Yup.object({
      signature: Yup.string().required(gettext('Required field')),
    }),
  ]
}
