import { TextField } from 'formik-mui'
import { Field, useFormikContext } from 'formik'

import { useTranslations } from 'common/services/translations'

import { RegistrationValues } from '../types'

type OtherEthnicityFieldProps = {
  name: string
}

export default function OtherEthnicityField({
  name,
}: OtherEthnicityFieldProps) {
  const { gettext } = useTranslations()
  const {
    touched,
    values: { ethnicity },
  } = useFormikContext<RegistrationValues>()

  if (!touched['ethnicity'] || !ethnicity?.value.includes('other')) {
    return null
  }

  return (
    <Field
      name={name}
      component={TextField}
      sx={{ width: '100%' }}
      InputLabelProps={{ shrink: true }}
      label={gettext('Ethnicity description')}
      placeholder={gettext('Please describe your ethnicity')}
    />
  )
}
