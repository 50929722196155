import { TextField } from 'formik-mui'
import { Field, useFormikContext } from 'formik'
import { FormHelperText, Grid, Stack, Typography } from '@mui/material'

import { GroupedChoice, Hospital, PaginationResponse } from 'types'
import { NHS_MASK } from 'common/constants/common'
import MaskField from 'common/components/MaskField'
import DatePicker from 'common/components/DatePicker'
import {
  AsyncSelectField,
  ChoicesStepperSelectField,
} from 'common/components/SelectField'
import { Protection } from 'common/components/Stepper/components'
import { interpolate, useTranslations } from 'common/services/translations'

import { RegistrationValues } from '../types'
import StudyLink from './StudyLink'
import OtherEthnicityField from './OtherEthnicityField'
import OtherHospitalField from './OtherHospitalField'
import { useRef } from 'react'

export default function ChildDetailsStep() {
  const { gettext } = useTranslations()
  const { values } = useFormikContext<RegistrationValues>()
  const notListedHospitalUuid = useRef<string | null>(null)

  return (
    <Stack gap={3}>
      <Typography variant="h5" fontWeight={600}>
        {interpolate(gettext('%s Registration'), [values.study.name])}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {gettext(
          'Please ensure that you have checked the study details and eligibility criteria before proceeding using the link below.'
        )}
      </Typography>
      <Stack direction="row">
        <StudyLink />
      </Stack>
      <Protection />
      <Field
        component={AsyncSelectField<Hospital>}
        name="hospital"
        endpoint="hospitals"
        queryOptions={{
          select: (data: PaginationResponse<Hospital>) => {
            // Find hospital containing "not listed" (case-insensitive)
            const notListedHospital = data.results.find((hospital) =>
              hospital.title.toLowerCase().includes('not listed')
            )

            // Filter out the "not listed" hospital from the original array
            const allHospitals = data.results.filter(
              (hospital) => !hospital.title.toLowerCase().includes('not listed')
            )

            // If "not listed" hospital exists, add it back to the end
            if (notListedHospital) {
              allHospitals.push(notListedHospital)
              notListedHospitalUuid.current = notListedHospital.uuid
            }

            return allHospitals
          },
        }}
        valueKey="uuid"
        labelKey="title"
        label={gettext('Registered Hospital for Birth')}
        placeholder={gettext('Select the Hospital')}
        inputLabel={{ shrink: true }}
        notched
        displayEmpty
        helperText={gettext(
          'If your hospital is not present in the dropdown, please select "Not Listed" at the end of the options.'
        )}
      />
      <OtherHospitalField
        name="hospital_if_not_listed"
        notListedHospitalUuid={notListedHospitalUuid.current}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Field
            component={TextField}
            name="first_name"
            label={gettext("Child's First Name")}
            placeholder={gettext('First Name')}
            sx={{ width: '100%' }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            component={TextField}
            name="last_name"
            label={gettext("Child's Last Name")}
            placeholder={gettext('Last Name')}
            sx={{ width: '100%' }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Field
        component={DatePicker}
        name="date_of_birth"
        label={gettext("Child's DOB")}
        placeholder={gettext('Select date')}
        disableFuture
        sx={{ width: '100%' }}
        views={['year', 'month', 'day']}
      />
      <Field
        component={MaskField}
        name="nhs"
        label={gettext("Child's NHS or CHI Number")}
        placeholder={gettext('000 000 0000')}
        mask={NHS_MASK}
        sx={{ width: '100%' }}
        InputLabelProps={{ shrink: true }}
        helperText={gettext(
          "Your child's NHS Number is in their red book. If you are in Scotland, please use your child's CHI number."
        )}
      />
      <Field
        component={ChoicesStepperSelectField}
        name="ethnicity"
        path="Patient.ethnicity"
        InputProps={{
          label: gettext('Ethnic Background'),
          placeholder: gettext('Ethnic Background'),
          InputLabelProps: { shrink: true },
          helperText: (
            <FormHelperText sx={{ m: 0 }}>
              {gettext(
                "Please select the option that best describes your child's ethnic background. This list is taken from the Office for National Statistics."
              )}
              <br />
              <em>
                {gettext(
                  'This information helps ensure that the technology we develop is effective and inclusive for all participants.'
                )}
              </em>
            </FormHelperText>
          ),
        }}
        groupBy={(option: GroupedChoice) => option.group}
      />
      <OtherEthnicityField name="ethnicity_description" />
    </Stack>
  )
}
