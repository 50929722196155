import { useCallback, useEffect, useRef, useState } from 'react'
import ReactSignatureCanvas from 'react-signature-canvas'
import { debounce } from 'lodash-es'
import { Box, Button, Stack } from '@mui/material'

import { useTranslations } from 'common/services/translations'

type SignChangeHandler = (sign: string) => void

const useSignature = (onChange: SignChangeHandler, defaultValue?: string) => {
  const boxRef = useRef<HTMLDivElement>(null)
  const padRef = useRef<ReactSignatureCanvas>(null)
  const [containerWidth, setContainerWidth] = useState(1)

  const onSign = useCallback(() => {
    onChange(padRef.current?.toDataURL() || '')
  }, [onChange])

  const onClear = useCallback(() => {
    padRef.current?.clear()
    onChange('')
  }, [onChange])

  const onResize = useCallback(() => {
    if (!boxRef.current) return
    onClear()
    setContainerWidth(boxRef.current.clientWidth)
  }, [onClear])

  useEffect(() => {
    if (boxRef.current) {
      setContainerWidth(boxRef.current.clientWidth)

      // set default value if present
      if (padRef.current && defaultValue) {
        padRef.current.fromDataURL(defaultValue, {
          width: boxRef.current.clientWidth,
          height: 200,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const resizeWindowHandler = debounce(onResize, 500)
    window.addEventListener('resize', resizeWindowHandler)
    return () => window.removeEventListener('resize', resizeWindowHandler)
  }, [onResize])

  return {
    boxRef,
    padRef,
    onSign,
    onClear,
    onResize,
    containerWidth,
  }
}

type SignatureFieldProps = {
  defaultValue?: string
  onChange: SignChangeHandler
}

export default function SignatureField({
  onChange,
  defaultValue,
}: SignatureFieldProps) {
  const { gettext } = useTranslations()
  const { boxRef, padRef, onClear, onSign, containerWidth } = useSignature(
    onChange,
    defaultValue
  )

  return (
    <Stack gap={3}>
      <Box
        width="100%"
        ref={boxRef}
        borderRadius={1}
        border="2px dashed"
        borderColor="secondary.light"
      >
        <ReactSignatureCanvas
          ref={padRef}
          onEnd={onSign}
          penColor="black"
          canvasProps={{
            height: '200px',
            width: containerWidth,
          }}
        />
      </Box>

      <Stack direction="row">
        <Button variant="outlined" onClick={onClear}>
          {gettext('Clear signature')}
        </Button>
      </Stack>
    </Stack>
  )
}
