import { useQuery } from '@tanstack/react-query'

import { Study } from 'types'
import Stepper from 'common/components/Stepper'
import useChoices from 'common/hooks/useChoices'
import { interpolate, useTranslations } from 'common/services/translations'
import { parseNHS } from 'common/utils/formatters/formatNHS'
import getSignature from 'pages/RequestForm/getSignature'

import stepsComponents from './steps'
import getValidationSchema from './validationSchema'
import { RegistrationValues } from './types'
import { Typography } from '@mui/material'

const getInitialValues = (study: Study): RegistrationValues => ({
  hospital: '',
  hospital_if_not_listed: '',
  study,
  first_name: '',
  last_name: '',
  email: '',
  date_of_birth: null,
  nhs: '',
  contact_number: '',
  ethnicity: null,
  ethnicity_description: '',
  guardian_first_name: '',
  guardian_last_name: '',
  guardian_relationship: null,
  signature: '',
})

export default function RegistrationForm() {
  const { gettext } = useTranslations()

  const uuid = getSignature()

  // prefetch choices
  useChoices()

  const {
    data,
    isLoading,
    error: requestError,
  } = useQuery<Study>({
    queryKey: [`studies/public/${uuid}`],
  })

  const handleSubmit = (formValues: RegistrationValues) => {
    return {
      ...formValues,
      nhs: parseNHS(formValues.nhs),
      ethnicity: formValues.ethnicity?.value,
      ethnicity_description: formValues.ethnicity?.value.includes('other')
        ? formValues.ethnicity_description
        : undefined,
      guardian_relationship: formValues.guardian_relationship?.value,
      date_of_birth: formValues.date_of_birth?.format('YYYY-MM-DD'),
      study: formValues.study.uuid,
    }
  }

  const schemes = getValidationSchema(gettext)

  const steps = stepsComponents.map((component, i) => ({
    component,
    fieldNames: Object.keys(schemes[i].fields),
  }))

  return (
    <Stepper
      isLoading={isLoading}
      requestError={requestError}
      formProps={{
        contentProps: {
          steps,
          wrapperProps: {
            nextText: gettext('Continue'),
            submitText: gettext('Join Study'),
          },
          successProps: {
            title: interpolate(gettext('Thank you for joining the %s!'), [
              data?.name as string,
            ]),
            description: (
              <>
                <Typography variant="h6">{gettext('Next steps')}</Typography>
                <ol>
                  <li>
                    {gettext(
                      "You will receive a confirmation email and text message with a link to a short form. This form will ask some final questions to confirm your contact details and your child's eligibility for the study."
                    )}
                  </li>
                  <li>
                    {gettext(
                      'We will be in touch with your first questionnaire shortly.'
                    )}
                  </li>
                </ol>
              </>
            ),
          },
        },
        formSubmitArgs: [`patients/registration`],
        onSubmit: handleSubmit,
        initialValues: getInitialValues(data!),
        validationSchema: schemes,
      }}
    />
  )
}
