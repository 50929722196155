import { TextField } from 'formik-mui'
import { Field, useFormikContext } from 'formik'
import { useTranslations } from 'common/services/translations'
import { RegistrationValues } from '../types'

type OtherHospitalFieldProps = {
  name: string
  notListedHospitalUuid: string | null
}

export default function OtherHospitalField({
  name,
  notListedHospitalUuid,
}: OtherHospitalFieldProps) {
  const { gettext } = useTranslations()
  const {
    values: { hospital },
  } = useFormikContext<RegistrationValues>()

  if (hospital !== notListedHospitalUuid) {
    return null
  }

  return (
    <Field
      name={name}
      component={TextField}
      sx={{ width: '100%' }}
      InputLabelProps={{ shrink: true }}
      label={gettext('Please specify your hospital if not listed')}
      placeholder={gettext('Please specify the hospital')}
    />
  )
}
