import { IMask } from 'react-imask'

import { NHS_MASK } from 'common/constants/common'

export default function formatNHS(nhs?: string): string {
  if (!nhs) return ''

  const masked = IMask.createMask({
    mask: NHS_MASK,
  })
  masked.resolve(nhs)
  return masked.value
}

export function parseNHS(nhs?: string): string | undefined {
  if (!nhs) return undefined
  const parsed = nhs.replace(/\s/g, '')
  return parsed || undefined
}
