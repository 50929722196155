import { groupBy, mapValues, values } from 'lodash-es'
import { Select, SelectProps } from 'formik-mui'
import {
  MenuItem,
  Box,
  FormControl,
  FormHelperText,
  ListSubheader,
} from '@mui/material'

export { default as AsyncSelectField } from './AsyncSelectField'
export { default as ChoicesSelectField } from './ChoicesSelectField'
export { default as StepperSelectField } from './StepperSelectField'
export { default as ChoicesStepperSelectField } from './ChoicesStepperSelectField'
export type { AutocompleteValue } from './StepperSelectField'

export type OptionValue = string | number

export type SelectFieldProps<Option extends Record<string, OptionValue>> =
  SelectProps & {
    options: Option[]
    valueKey?: keyof Option
    labelKey?: keyof Option
    groupKey?: keyof Option
    placeholder?: string
    helperText?: string
  }

export default function SelectField<
  Option extends Record<string, OptionValue>,
>({
  options,
  valueKey = 'value',
  labelKey = 'label',
  groupKey = 'group',
  placeholder,
  helperText,
  ...props
}: SelectFieldProps<Option>) {
  const renderMenuItem = ({
    [valueKey]: optionValue,
    [labelKey]: optionLabel,
  }: Option) => (
    <MenuItem key={optionValue} value={optionValue}>
      {optionLabel}
    </MenuItem>
  )

  const items = options.some((opt) => groupKey in opt)
    ? values(
        mapValues(groupBy(options, groupKey), (group, groupName) => [
          <ListSubheader key={groupName}>{groupName}</ListSubheader>,
          ...group.map(renderMenuItem),
        ])
      ).flat()
    : options.map(renderMenuItem)

  return (
    <FormControl fullWidth>
      <Select {...props}>
        {placeholder && (
          <MenuItem disabled value="">
            <Box sx={{ color: 'text.disabled' }}>{placeholder}</Box>
          </MenuItem>
        )}
        {items}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}
