import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import SelectField, { OptionValue, SelectFieldProps } from '.'

type Props<Option extends Record<string, OptionValue>> =
  SelectFieldProps<Option> & {
    endpoint: string
    params?: object
    queryOptions?: UseQueryOptions<Option[]>
    helperText?: string
  }

export default function AsyncSelectField<
  Option extends Record<string, OptionValue>,
>({
  endpoint,
  params = {},
  queryOptions,
  field,
  helperText,
  ...props
}: Props<Option>) {
  const { data: options, isLoading } = useQuery<Option[]>({
    queryKey: [endpoint, params],
    ...queryOptions,
  })

  return (
    <SelectField
      {...props}
      field={
        !isLoading
          ? field
          : {
              ...field,
              value: '',
            }
      }
      options={options || []}
      helperText={helperText} // Pass the helperText prop
    />
  )
}
