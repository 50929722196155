import { Button } from '@mui/material'
import { useFormikContext } from 'formik'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import { useTranslations } from 'common/services/translations'

import { RegistrationValues } from '../types'

export default function StudyLink() {
  const { gettext } = useTranslations()
  const {
    values: { study },
  } = useFormikContext<RegistrationValues>()

  return (
    <Button
      type="text"
      target="_blank"
      LinkComponent="a"
      endIcon={<OpenInNewIcon />}
      href={`/studies/${study.uuid}`}
    >
      {gettext('Learn more about study')}
    </Button>
  )
}
